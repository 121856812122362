@charset "UTF-8";
/*.right-off-canvas-menu .off-canvas-list,
.left-off-canvas-menu .off-canvas-list{
	position: relative;
}*/
.right-off-canvas-menu a.bt,
.left-off-canvas-menu a.bt {
  text-decoration: underline; }

.right-off-canvas-menu .hasSubMenu > ul,
.left-off-canvas-menu .hasSubMenu > ul {
  -webkit-backface-visibility: hidden;
  width: 15.625rem;
  left: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1002;
  background: #333333;
  box-sizing: content-box;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: -webkit-transform 500ms ease;
  -moz-transition: -moz-transform 500ms ease;
  -ms-transition: -ms-transform 500ms ease;
  -o-transition: -o-transform 500ms ease;
  transition: transform 500ms ease; }

.left-off-canvas-menu .hasSubMenu > ul {
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  -ms-transform: translate(-100%, 0);
  -ms-transform: translate3d(-100%, 0, 0);
  -o-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.right-off-canvas-menu .hasSubMenu > ul {
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  -ms-transform: translate(100%, 0);
  -ms-transform: translate3d(100%, 0, 0);
  -o-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.right-off-canvas-menu .hasSubMenu.select > ul,
.left-off-canvas-menu .hasSubMenu.select > ul {
  -webkit-transform: translate3d(0%, 0, 0);
  -moz-transform: translate3d(0%, 0, 0);
  -ms-transform: translate(0%, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0); }

.right-off-canvas-menu .hasSubMenu > .bt,
.left-off-canvas-menu .hasSubMenu > .bt {
  margin-right: 2.8125rem; }

.right-off-canvas-menu .hasSubMenu .subMenuToggle,
.left-off-canvas-menu .hasSubMenu .subMenuToggle {
  position: absolute;
  padding: 0;
  margin: 0;
  font-size: 1.4em;
  background-color: transparent;
  height: 2.8125rem;
  line-height: 2.5125rem;
  text-align: center;
  width: 2.8125rem;
  border: none;
  color: #333;
  border-left: solid 1px #333;
  right: 0; }

.left-off-canvas-menu .hasSubMenu .subMenuToggle:before {
  content: '»'; }

.right-off-canvas-menu .hasSubMenu .subMenuToggle:before {
  content: '«'; }

.right-off-canvas-menu .hasSubMenu .subMenuToggle:hover,
.left-off-canvas-menu .hasSubMenu .subMenuToggle:hover {
  background-color: #FFF;
  background-color: rgba(255, 255, 255, 0.5); }

.right-off-canvas-menu .hasSubMenu .back > a,
.left-off-canvas-menu .hasSubMenu .back > a {
  text-decoration: none; }

.right-off-canvas-menu .hasSubMenu .back > a:after,
.left-off-canvas-menu .hasSubMenu .back > a:before {
  content: "«";
  display: inline-block;
  font-size: 1.4em;
  line-height: 1em;
  text-align: center;
  width: 2.8125rem; }

.right-off-canvas-menu .hasSubMenu .back > a {
  text-align: right; }

.right-off-canvas-menu .hasSubMenu .back > a:after {
  content: '»'; }
